/* eslint-disable no-use-before-define */
import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import emailjs from 'emailjs-com';
import axios from "axios";

import Utils from '../Utils';
import CONFIG from "../../config.json";

import ProductItem from "../Products/ProductItem";

import TotalsItem from "../Totals/TotalsItem";
import TotalsHeader from "../Totals/TotalsHeader";
import TotalsFooter from "../Totals/TotalsFooter";

import SearchHeader from "./SearchHeader";

import PlateCost from "../PlateCost/PlateCost";
import PlateCostTotals from "../PlateCost/PlateCostTotals";

import SearchNoResults from "./SearchNoResults";
import Masthead from "../Masthead/Masthead";
import TotalsPlateCost from "../Totals/TotalsPlateCost";

import SendMail from "../SendMail/SendMail";

class Search extends Component {
	url = CONFIG.SERVER_URL + 'wp-json/acf/' + CONFIG.API_VERSION + '/options/';

	constructor(props) {
		super(props);

		this.state = {
			results : [],
			totalMealCostVAT : 0,
			totalCasePriceVAT : 0,
			totalRSP : 0,

			// Plate Cost
			proteins : [],
			starches : [],
			sides : [],
			addedProteins : [
				{
					'value' : '',
					'added' : false,
					'cost' : 0
				}
			],
			addedStarches : [
				{
					'value' : '',
					'added' : false,
					'cost' : 0
				},
				{
					'value' : '',
					'added' : false,
					'cost' : 0
				}
			],
			addedSides : [
				{
					'value' : '',
					'added' : false,
					'cost' : 0
				},
				{
					'value' : '',
					'added' : false,
					'cost' : 0
				}
			],

			// Plate Cost
			totalPlateCost: 0.00,
			percentageGP: 0,
			mealsPerDay: 0,
			recommendedRSP: 0.00,
			totalMealRevenue: 0.00,

			// email sending
			emailButtonClick : false,

			// agent information
			siteName : '',
			siteEmail: '',
			consultantName : '',
			consultantContact :  '',
			consultantEmail :  ''
		};

		this.handleClearItems = this.handleClearItems.bind(this);
		this.handleSearchSuggestion = this.handleSearchSuggestion.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.handlePriceIngredient = this.handlePriceIngredient.bind(this);
		this.handleAddIngredient = this.handleAddIngredient.bind(this);
		this.handlePlateCostTotalsChange = this.handlePlateCostTotalsChange.bind(this);

		this.handleAgentInfo = this.handleAgentInfo.bind(this);
	}


	componentDidMount() {
		this.fetchPostData();
		this.updateAgentInfo();
	}

	fetchPostData() {
		axios.get(this.url)
		.then(res => {
			let data = res.data.acf;
			this.setState({
				proteins : data.proteins,
				starches : data.starches,
				sides : data.sides
			});
		});
	}



	// Products Logic
	// ------------------------------------------------------------
	calculateCasePrice (value, index, type) {
		if(!value) {
			value = 0;
		}
		let state = this.state.results;
		let cost_per_portion = 0;

		if(type === CONFIG.PRODUCTTYPES[0] ) { // Products
			cost_per_portion = value / state[index].acf.portions_per_case;
		}

		if(type === CONFIG.PRODUCTTYPES[1] ) { // Spreads
			cost_per_portion = value  / state[index].acf.grams_per_case * state[index].portions_per_meal;
		}

		state[index].case_price = parseFloat( value );
		state[index].case_price_vat = Utils.addVat(value);
		state[index].cost_per_portion = parseFloat( cost_per_portion );
		state[index].cost_per_portion_vat = Utils.addVat(cost_per_portion);

		this.setState({
			results : state
		});
	}

	calculatePortionsPerMeal (value, index) {
		if(!value) {
			value = 0;
		}
		let state = this.state.results;
		let total_meal_cost =  value * state[index].cost_per_portion;

		state[index].portions_per_meal = parseFloat( value );
		state[index].total_meal_cost = parseFloat( total_meal_cost );
		state[index].total_meal_cost_vat = Utils.addVat(total_meal_cost);

		this.setState({
			results : state
		});
	}

	calculatePercentageGP (value, index) {
		if(!value) {
			return false;
		};
		let state = this.state.results;
		let rsp_per_meal = (state[index].cost_per_portion_vat * value / 100) + state[index].cost_per_portion_vat;

		state[index].percentage_gp = parseFloat( value );
		state[index].rsp_per_meal = parseFloat( rsp_per_meal );

		this.setState({
			results : state
		});

	}

	calculateTotals() {
		let state = this.state.results,
			totalMealCostVAT = 0,
			totalCasePriceVAT = 0,
			totalRSP = 0;

		state.forEach((item) => {
			totalMealCostVAT = totalMealCostVAT + item.total_meal_cost_vat;
			totalCasePriceVAT = totalCasePriceVAT + item.case_price_vat;
			totalRSP = totalRSP + item.rsp_per_meal;
		});

		this.setState({
			totalCasePriceVAT : totalCasePriceVAT,
			totalMealCostVAT : totalMealCostVAT,
			totalRSP : totalRSP
		});

	}

	handleChange(index, e) {
		let value 		= e.target.value,
			id 			= e.target.id,
			item 		= this.state.results[index],
			product_type = item.product_type;

		switch (id) {
			case 'case-price':
				this.calculateCasePrice(value, index, product_type);
				this.calculatePortionsPerMeal(item.portions_per_meal, index);
				this.calculatePercentageGP(item.percentage_gp, index);
			break;

			case 'portion-per-meal' :
				this.calculateCasePrice(item.case_price, index, product_type);
				this.calculatePortionsPerMeal(value, index);
				this.calculatePercentageGP(item.percentage_gp, index);
			break;

			case 'percentage-gp' :
				this.calculateCasePrice(item.case_price, index, product_type);
				this.calculatePortionsPerMeal(item.portions_per_meal, index);
				this.calculatePercentageGP(value, index);
			break;
			default:
			break;

		}

		this.calculateTotals();
	};

	handleClearItems() {
		this.setState({
			results : []
		});
	};


	// Choose a search result
	// ------------------------------------------------------------
	handleSearchSuggestion = (e, value) => {
		let results = this.state.results;
		if(value) {
			results.unshift(value);
			this.setState({
				results: results
			});
			this.calculateTotals();
		}
	};





	// Plate Cost Logic
	// ------------------------------------------------------------
	handlePriceIngredient = (value, type, index) => {
		let values = [];

		if(!value) {
			value = 0
		}

		switch (type) {
			case 'Proteins':
				values = this.state.addedProteins;
				values[index].cost = value;

				this.setState({
					addedProteins : values
				});
			break;

			case 'Starches':
				values = this.state.addedStarches;
				values[index].cost = value;

				this.setState({
					addedStarches : values
				});

			break;

			case 'Sides':
				values = this.state.addedSides;
				values[index].cost = value;

				this.setState({
					addedSides : values
				});
			break;

			default:
			break;
		}

		this.calculateTotalMealRevenue(this.state.mealsPerDay);
		this.calculateRSP(this.state.percentageGP);
		this.calculateTotalPlateCost();
	}

	handleAddIngredient = (added, value, type, index) => {
		let values = [];

		switch (type) {
			case 'Proteins':
				values = this.state.addedProteins;
				values[index].value = value;
				values[index].added = added;

				this.setState({
					addedProteins: values
				});
			break;

			case 'Starches':
				values = this.state.addedStarches;
				values[index].value = value;
				values[index].added = added;

				this.setState({
					addedStarches : values
				});
			break;

			case 'Sides':
				values = this.state.addedSides;
				values[index].value = value;
				values[index].added = added;

				this.setState({
					addedSides : values
				});
			break;

			default:
			break;
		}

	}

	calculateTotalPlateCost() {
		let state = this.state,
			total = 0;

		state.addedProteins.forEach( (item) => {
			total += parseFloat(item.cost);
			return false;
		});

		state.addedStarches.forEach( (item) => {
			total += parseFloat(item.cost);
			return false;
		});
		state.addedSides.forEach( (item) => {
			total += parseFloat(item.cost);
			return false;
		});

		this.setState({
			totalPlateCost : total
		});
	}


	// Plate Cost Totals Logic
	// ------------------------------------------------------------
	calculateRSP = (value) => {
		if(!value || value < 0) {
			value = 0;
		}

		// Recommended RSP Incl. Vat = Total Plate Cost Incl. Vat + (Total Plate Cost Incl. Vat * %GP)
		let percentageGP = value,
			totalplatecostVAT = Utils.addVat(this.state.totalPlateCost),
			recommendedRSP = totalplatecostVAT + ( totalplatecostVAT * percentageGP / 100 );

		this.setState({
			percentageGP : percentageGP,
			recommendedRSP : recommendedRSP
		});
	}

	calculateTotalMealRevenue = (value) => {
		if(!value || value < 0) {
			value = 0;
		}

		// Total Meal Revenue = Recommended RSP Incl. Vat * Meals Per Day
		let mealsPerDay = value,
			totalMealRevenue = this.state.recommendedRSP * mealsPerDay;

		this.setState({
			mealsPerDay : mealsPerDay,
			totalMealRevenue : totalMealRevenue
		});
	}

	handlePlateCostTotalsChange = (e) => {
		let value 		= e.target.value,
			id 			= e.target.id;

		switch (id) {
			case 'percentage-gp':
				this.calculateRSP(value);
				this.calculateTotalMealRevenue(this.state.mealsPerDay);
			break;

			case 'meals-per-day' :
				this.calculateRSP(this.state.percentageGP);
				this.calculateTotalMealRevenue(value);
			break;

			default:
			break;
		}
	};


	// Email totals
	// ------------------------------------------------------------
	emailTotals = () => {
		let products = '';

		this.state.results.forEach( (item, index) => {
			products += `${item.fulltitle}`;
			if(index !== this.state.results.length - 1) {
				products += ' • ';
			}
		});

		var params = {
			siteEmail: this.state.siteEmail,
			siteName : this.state.siteName,
			consultantName : this.state.consultantName,
			consultantContact : this.state.consultantContact,
			consultantEmail : this.state.consultantEmail,

			products : products,

			totalMealCostVAT : Utils.formatPrice( this.state.totalMealCostVAT ),
			totalCasePriceVAT : Utils.formatPrice( this.state.totalCasePriceVAT ),
			totalRSP : Utils.formatPrice( this.state.totalRSP ),
			totalPlateCost: Utils.formatPrice( this.state.totalPlateCost ),
			totalPlateCostVAT: Utils.formatPrice( Utils.addVat(this.state.totalPlateCost) ),
			percentageGP: this.state.percentageGP,
			mealsPerDay:  this.state.mealsPerDay,
			recommendedRSP: Utils.formatPrice( this.state.recommendedRSP ),
			totalMealRevenue: Utils.formatPrice( this.state.totalMealRevenue )
		};

		this.setState({
			emailButtonClick : true
		});


		emailjs.send( CONFIG.EMAIL_SERVICE, CONFIG.EMAIL_TEMPLATE, params, CONFIG.EMAIL_USER_ID)
		.then((result) => {
			console.log('EMAIL SENT SUCCESSFULLY');

			this.setState({
				emailButtonClick : false
			});

			let agentInfo = {
				consultantName : this.state.consultantName,
				consultantContact : this.state.consultantContact,
				consultantEmail : this.state.consultantEmail
			};

			localStorage.setItem('agentInfo', JSON.stringify(agentInfo) );

		}, (error) => {
			console.log(error.text);
			this.setState({
				emailButtonClick : false
			});
		});

	}



	// Agent Info
	// ------------------------------------------------------------
	updateAgentInfo = () => {
		const agentInfo = JSON.parse( localStorage.getItem('agentInfo') );

		if(agentInfo) {
			this.setState({
				consultantName : agentInfo.consultantName,
				consultantContact : agentInfo.consultantContact,
				consultantEmail : agentInfo.consultantEmail
			})
		}
	}

	handleAgentInfo = (e) => {

		let id = e.target.id,
			value = e.target.value

		switch (id) {
			case 'site-name':
				this.setState({
					siteName : value
				});
			break;
			case 'site-email':
				this.setState({
					siteEmail : value
				});
			break;
			case 'consultant-name':
				this.setState({
					consultantName : value
				});
			break;
			case 'consultant-contact':
				this.setState({
					consultantContact : value
				});
			break;
			case 'consultant-email':
				this.setState({
					consultantEmail : value
				});
			break;

			default:
			break;
		}
	}


	render() {

		return (
			<React.Fragment>

				<Masthead></Masthead>

				<Switch>
					<Route path="/platecost">

						<PlateCost
							data={this.state}
							handlePriceIngredient={this.handlePriceIngredient}
							handleAddIngredient={this.handleAddIngredient}>
						</PlateCost>

						<PlateCostTotals
							handlePlateCostTotalsChange={this.handlePlateCostTotalsChange}
							data={this.state}></PlateCostTotals>

					</Route>

					<Route path="/totals">

						{ (this.state.results.length > 0 ) &&
							<table className="products__totals / content-box mb-3">
								<TotalsHeader />

								<tbody>
								{this.state.results.map((p, index) => (
									<TotalsItem key={p.id + '__' + index} index={index} product={p} />
								))}
								</tbody>

								<TotalsFooter totalCasePriceVAT={this.state.totalCasePriceVAT} totalMealCostVAT={this.state.totalMealCostVAT} totalRSP={this.state.totalRSP}></TotalsFooter>
							</table>
						}

						{ (this.state.results.length === 0 ) &&
							<SearchNoResults />
						}


						<TotalsPlateCost data={this.state}></TotalsPlateCost>

					</Route>

					<Route path="/email">
						<SendMail data={this.state} handleAgentInfo={this.handleAgentInfo} emailTotals={this.emailTotals}></SendMail>
					</Route>

					<Route path="/products">
						<SearchHeader
							data={this.props.data}
							handleClearItems={this.handleClearItems}
							handleSearchSuggestion={this.handleSearchSuggestion}></SearchHeader>

						<div className="products__grid / row align-items-end">
							{this.state.results.map((p, index) => (
								<ProductItem key={p.id + '__' + index} index={index} handleChange={this.handleChange} product={p} />
							))}
						</div>
						{ (this.state.results.length === 0 ) &&
							<SearchNoResults />
						}
					</Route>

					<Redirect exact from="/" to="/products" />

					<Route path="/"></Route>

				</Switch>

			</React.Fragment>
		);
	}
}
export default Search;
