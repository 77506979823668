import React, { PureComponent } from 'react';
import Utils from '../Utils';


export default class TotalsFooter extends PureComponent {

	render() {
		return (
			<tfoot>
				<tr>
					<td className="totalamount">
						R{ Utils.formatPrice(this.props.totalCasePriceVAT)}
					</td>
					<td className="totalamount">
						R{ Utils.formatPrice(this.props.totalMealCostVAT)}
					</td>
					<td className="totalamount">
						R{ Utils.formatPrice(this.props.totalRSP)}
					</td>
				</tr>
			</tfoot>
		)
	}
}